import React from 'react';

import './Writing.scss';

function Writing() {
  return (
    <div className="writingPage">
      TODO
    </div>
  );
}

export default Writing;
